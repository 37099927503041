<template>
  <div class="share-page">
    <headerBar :title="i18n.t('share.title')"  :showArrow="true" @back="back"></headerBar>

    <div class="title">{{i18n.t('share.label1')}}</div>
    <div class="title">{{i18n.t('share.label2')}}</div>



    <div class="share-info">
      <div class="flex-center-between">
        <div class="info" v-if="share_title === 0">
          <div>{{i18n.t('share.label3')}}</div>
          <div>{{i18n.t('share.label4')}}</div>
          <div>{{i18n.t('share.label5')}}</div>
          <div>{{i18n.t('share.label6')}}</div>
        </div>
        <div class="info" v-if="share_title === 1">
          <div>{{i18n.t('share.label8')}}</div>
        </div>
        <div class="qrcode" ref="qrcode"></div>
      </div>

      <div class="share-code">
         {{i18n.t('share.label7')}}：{{shareCode}}
      </div>
    </div>
  </div>
</template>
<script>
import { Button } from 'vant'
import headerBar from '@/components/header'
import QRCode from 'qrcodejs2'
import { userInfo } from '@/api/user'
export default {
  components: {
    headerBar,
    Button
  },
  data() {
    return {
      shareUrl: require('../../assets/imgs/share.png'),
      shareCode: '',
    }
  },
  computed: {
    shareLink() {
      return this.$store.state.appPromotion
    },
    share_title() {
      return this.$store.state.share_title
    }
  },
  created() {
    this.shareCode = window.localStorage.getItem("shareCode")
  },
  mounted() {
    this.init()
  },
  methods: {
    back() {
      this.$router.go(-1)
    },
    init() {
      userInfo().then(res => {
        this.shareCode = res.data.InvitationCode
        window.localStorage.setItem("shareCode", res.data.InvitationCode)
        let link = this.shareLink + '?code=' + this.shareCode
        try {
          let qrcode = new QRCode(this.$refs.qrcode, {
            text: link,
            width: 90,
            height: 90,
            correctLevel: QRCode.CorrectLevel.H
          })
        } catch (e) {
          console.log(e)
        }
      })
    },
  }
}
</script>